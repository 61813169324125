import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/react-hooks';
import { getBEMClassName, removeItemFromLocalStorage } from '@netfront/common-library';
import { useContentPageContext } from '@netfront/ekardo-content-library';
import {
  FormFieldContainer,
  FORM_ELEMENT_CSS_IDENTIFIERS,
  ILoginOnCompletedResponse,
  LoginForm,
  saveAuthenticationData,
  useDomain,
  useLogin,
  useLogout,
} from '@netfront/gelada-identity-library';
import { Breadcrumbs, IBreadcrumbItem } from '@netfront/ui-library';
import cx from 'classnames';
import kebabCase from 'lodash.kebabcase';
import { useRouter } from 'next/router';

import { AdvertisementApp } from '../components/AdvertisementApp';
import { Affiliates } from '../components/Affiliates';
import { BreadcrumbItemHomePageLink, BreadcrumbItem } from '../components/BreadcrumbItems';
import { Button, BUTTON_CLASSES } from '../components/Button';
import { HelpButton } from '../components/HelpButton';
import { PageLayout } from '../components/PageLayout';
import { REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS } from '../components/RegistrationForm';
import { useToast } from '../hooks/useToast';

const PAGE_TITLE = 'Login';

const BREADCRUMB_ITEMS: IBreadcrumbItem[] = [
  {
    content: <BreadcrumbItemHomePageLink />,
    key: 'home',
  },
  {
    content: <BreadcrumbItem text={PAGE_TITLE} />,
    key: kebabCase(PAGE_TITLE),
  },
];

const LoginPage = () => {
  const { button: buttonElementCssId, container: containerElementCssId, register: registerElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const { form: formBlockCssId } = REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS;

  const { dispatch } = useContentPageContext();
  const { push, prefetch, query } = useRouter();
  const { handleToastError } = useToast();

  const { requestedRoute } = query;

  const navigateToUrlAfterLoginCompleted = requestedRoute ? String(requestedRoute) : '/dashboard';

  const { getDomain, isDomainReady } = useDomain();
  const [domain, setDomain] = useState<string>('');

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    setDomain(getDomain());
  }, [isDomainReady]);

  const handleLoginCompleted = ({ accessToken, refreshToken, user }: ILoginOnCompletedResponse) => {
    saveAuthenticationData({
      accessToken,
      refreshToken,
      user,
      accessTokenOptionalCookieAttributes: {
        domain: getDomain(),
      },
      refreshTokenOptionalCookieAttributes: {
        domain: getDomain(),
      },
    });

    push(navigateToUrlAfterLoginCompleted).catch((error) =>
      handleToastError({
        shouldUseFriendlyErrorMessage: true,
        error,
      }),
    );
  };

  const handleLoginError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleLogoutCompleted = () => {
    dispatch({
      type: 'unloadContentPage',
    });

    removeItemFromLocalStorage('userFlowIds');

    // eslint-disable-next-line no-console
    console.log('Automatic logout');
  };

  const handleRegister = () => {
    push('/register').catch((error) =>
      handleToastError({
        shouldUseFriendlyErrorMessage: true,
        error,
      }),
    );
  };

  const { handleLogin, isLoading = false } = useLogin({
    onCompleted: handleLoginCompleted,
    onError: handleLoginError,
  });

  const { handleLogout } = useLogout({
    onCompleted: handleLogoutCompleted,
  });

  useEffect(() => {
    prefetch(navigateToUrlAfterLoginCompleted).catch((error) =>
      handleToastError({
        shouldUseFriendlyErrorMessage: true,
        error,
      }),
    );

    handleLogout({ domain });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerButton = (
    <FormFieldContainer
      css={{
        blockId: formBlockCssId,
        elementId: `${registerElementCssId}-${buttonElementCssId}-${containerElementCssId}`,
      }}
    >
      <Button
        aria-label="Register"
        className={cx(getBEMClassName(formBlockCssId, `${registerElementCssId}-${buttonElementCssId}`), BUTTON_CLASSES.default)}
        onPress={handleRegister}
      >
        Register
      </Button>
    </FormFieldContainer>
  );

  return (
    <PageLayout isPreloaderVisible={isLoading} meta={{ description: 'Get access to your account' }} title={PAGE_TITLE}>
      <div className="container-md flex justify-end py-8">
        <Breadcrumbs items={BREADCRUMB_ITEMS} />
      </div>

      <section className="container-md pb-8 pt-4">
        <div className="flex flex-col md:flex-row">
          <div className="mb-4 md:mb-0 md:w-4/5 w-full">
            <h1 className="color-accent h4 mb-2 weight-800">{PAGE_TITLE}</h1>
            <h2 className="color-blue-900 h2">Get access to your account</h2>
            <hr className="bg-grey-100 block border-none h-0-5 ml-0 mt-7 radius-full w-third" role="presentation" />
          </div>

          <div className="align-center flex w-full">
            <div className="bg-grey-100 flex-1 h-0-5 md:flex mx-8 none radius-full" role="presentation" />
            <HelpButton />
          </div>
        </div>
      </section>

      <section className="pb-12 pt-4">
        <div className="container-md flex flex-col md:flex-row">
          <div className="mb-12 md:mb-0 md:mr-24 w-full">
            <LoginForm
              buttonClassName={BUTTON_CLASSES.default}
              forgotPasswordUrl="/forgot-password"
              isSubmitting={isLoading}
              registerButton={registerButton}
              onLogin={(login, password) => handleLogin({ login, password })}
            />
          </div>
          <div className="flex justify-center md:w-3/4 w-full">
            <img alt="Cartoon of user engaging with the CarerWell program" loading="lazy" src="/images/pre-chapter-illustration.svg" />
          </div>
        </div>
      </section>

      <AdvertisementApp />

      <Affiliates isAddYMargin />
    </PageLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default LoginPage;
